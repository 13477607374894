export default {
  returnFAQ: [
    { question: 'Example question in BE?', answer: 'Example answer in BE.' },
    { question: 'Second question in BE?', answer: 'Second answer in BE.' },
    { question: 'Another question in BE?', answer: 'Another answer in BE.' },
  ],
  trackingFAQ: [
    { question: 'Example question about tracking in BE?', answer: 'Example answer about tracking in BE.' },
    { question: 'Second example question about tracking in BE?', answer: 'Second example answer about tracking in BE.' },
    { question: 'Another example question about tracking in BE?', answer: 'Another example answer about tracking in BE.' },
  ],
  trackingProviders: [
    { name: 'Tracking DPD', description: 'Tracking description in BE', url: 'https://gls-group.eu/BE/be/', logo: 'https://www.miinto.be/static/graphics/dpd-logo.png' },
  ]
}
